export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value'
  }, {
    prop: 'shop_item',
    label: '',
    Object: 'value'
  }, {
    prop: 'image',
    label: '',
    span: 24,
    image: true
  }, {
    prop: 'price',
    label: '',
    Object: 'value'
  }, {
    prop: 'saling_stock',
    label: '',
    Object: 'value'
  }, {
    prop: 'sold',
    label: '',
    Object: 'value'
  }, {
    prop: 'warning_stock',
    label: '',
    Object: 'value'
  }]
}
