import { getShopItemStocks } from '@/services/product'
import viewFrame from '@/components/viewFrame'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import loading from '@/utils/loading.json'
export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  data () {
    return {
      urlParams: null,
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: []
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    async init () {
      const _this = this
      Object.assign(this.titleObject, { title: `${_this.$t('menu.shopItemStock')} `, data: _this.urlParams.id })
      _this.setGlobalLoading(true)
	  const res = await getShopItemStocks(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.$message({
		    type: 'success',
		    message: _this.$t('message.loadSuccess')
		  })
		  _this.custName = res.data.shop_item_stock
	  }
       _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'ShopItemStock', item_id: _this.urlParams.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    handleBasicInfoForwardToPage (data, name) {
      if (name === 'shop_item') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'shop_item_stock') {
        this.$router.push({
          name: 'goodsManagerVariationsDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleEditClick () {
      this.$router.push({
        name: 'goodsManagerVariationsEdit',
        params: {
          id: this.$route.params.id
        }
      })
    }
  }
}
