<template>
  <div class="managerDeliveryOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn>
        <el-button type="primary" size="mini" @click="handleEditClick">{{ $t('text.edit') }}</el-button>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('text.basicInfo')}}</span>
              <el-card class="animate__animated animate__fadeInDown" shadow="hover">
                <custome-table tableType="object" :custSpan="6" :data="custName.basic_information" :dataItem="basicViewItem" @forwardToPage="handleBasicInfoForwardToPage"></custome-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.common.changeHistory')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
